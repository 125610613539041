<template>
  <div>
    <div class="paymentDetail-info mb-3" v-if="plan.bought_sender === false">
      <label for="bulk_agreement" class="cr" v-html="$t('forms.buyPackages.noSenderInfoDescription')"></label>
      <div class="pb-1 d-flex justify-content-between">
        <alert type="primary" text="forms.buyPackages.noSenderWarningAlert" class="mb-0 font-weight-bold" />
      </div>
      <div class="pb-1 d-flex justify-content-between">
        <alert type="info" text="forms.buyPackages.noSenderNamesAlert" class="mb-0 font-weight-normal" />
      </div>
    </div>
    <h4 class="text-center m-0">{{ $t('purchasedTabs.sender_name_title') }}</h4>
    <div class="font-weight-bold mt-3 text-center paymentDetail-param_plan paymentDetail-param_plan_main">
      {{ plan.localized_price }}
    </div>
    <div class="font-weight-bold mb-4 text-center paymentDetail-monthly_plan paymentDetail-param_plan_main">
        {{$t('forms.buyPackages.monthly')}}
    </div>
    <div class="text-center mt-2 mb-3">
      <div>
        {{ $t('purchasedTabs.sender_name_fee') }} {{ plan.localized_fee }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button @click.prevent="submit" class="btn btn-primary btn-lg shadow-2">{{ $t('forms.buyPackages.continue') }}</button>
    </div>
    <slot></slot>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
  name: 'BuySenderNameForm',
  props: {
    plan: {
      type: Object,
    },
  },
  components: {
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  emits: ['select-package'],
  setup(props, { emit }) {
    return {
      submit: async() => {
        emit('select-package', props.plan)
      },
    }
  },
}
</script>
